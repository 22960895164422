import Button from 'react-bootstrap/Button';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useTitle, useAuth } from 'hooks';
import { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as yup from 'yup';
import type { FormikHelpers } from 'formik';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import Legal from 'components/Legal';
import { emailRule } from 'validationRules';

const schema = yup.object().shape({
  email: emailRule,
});

type VerifyRequest = {
  email: string;
  genericError: string;
};

export default function ForgotPassword() {
  const { t } = useTranslation(['forgot-password', 'common']);

  useTitle(t('Forgot Password'));

  const navigate = useNavigate();
  const { forgotPassword } = useAuth();
  const [searchParams] = useSearchParams();
  const providedEmail = searchParams.get('email');

  const handleSubmit = useCallback(
    async (
      values: VerifyRequest,
      { setErrors, setStatus }: FormikHelpers<VerifyRequest>
    ): Promise<void> => {
      try {
        const response = await forgotPassword({
          username: values.email,
        });

        if (!response.success) {
          throw new Error(response.errorCode);
        }

        navigate({
          pathname: '/reset-password',
          search: `?${createSearchParams({ email: values.email })}`,
        });
      } catch (err: any) {
        setStatus({ success: false });

        const localizedError =
          t(`errors.${err.message}`, '') ||
          t(`errors.${err.message}`, { ns: 'common', defaultValue: '' }) ||
          t('errors.UnknownException', { ns: 'common' });

        setErrors({ genericError: localizedError });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forgotPassword, navigate]
  );

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      email: providedEmail || '',
      genericError: '',
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <h1 className="mb-0 font-weight-bold text-center">
        {t('Forgot Password')}
      </h1>
      <p className="mb-6 text-center text-muted">
        {t(
          'Enter your email address and we will send you a link to reset your password.'
        )}
      </p>
      <Form noValidate className="mb-2 mt-2" onSubmit={formik.handleSubmit}>
        <Form.Group className="form-group">
          <Form.Control
            required
            autoFocus
            tabIndex={1}
            type="email"
            name="email"
            className="form-control"
            placeholder={t('Your email address', { ns: 'common' }) || ''}
            autoComplete="off"
            value={formik.values.email}
            onChange={formik.handleChange}
            isInvalid={!!formik.errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {!!formik.errors.email && <>{formik.errors.email}</>}
          </Form.Control.Feedback>
        </Form.Group>

        {formik.errors.genericError && (
          <p className="text-danger text-center fs-6 mt-5">
            {formik.errors.genericError}
          </p>
        )}

        <Button
          type="submit"
          variant="primary"
          className="btn-block"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? (
            <Spinner
              as="span"
              animation="border"
              variant="light"
              role="status"
              aria-hidden="true"
            />
          ) : (
            t('Reset Password')
          )}
        </Button>
      </Form>

      <Legal />
    </>
  );
}
