import Login from 'pages/login';
import Register from 'pages/register';
import RegisterConfirm from 'pages/register/confirm';
import ForgotPassword from 'pages/forgot-password';
import PasswordReset from 'pages/password-reset';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { useAuth } from 'hooks';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect } from 'react';

const AppRoutes = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isAuthenticated } = useAuth();

  /* useEffect(() => {
    if (isAuthenticated) {
      window.location.href =
        process.env.REACT_APP_AUTHORIZED_REDIRECT_URL ||
        'https://collegecode.com';
    }
  }, [isAuthenticated]); */
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/verify" element={<RegisterConfirm />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route index element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
