import { useTitle } from 'hooks';
import { useTranslation } from 'react-i18next';
import Legal from 'components/Legal';
import LoginOAuthProviders from './LoginOAuthProviders';
import LoginEmailProvider from './LoginEmailProvider';

export default function Login() {
  const { t } = useTranslation(['login', 'common']);

  useTitle(t('Login'));

  return (
    <>
      <h1 className="mb-0 font-weight-bold text-center">{t('Login')}</h1>

      <p className="mb-6 text-center text-muted">
        {t('Unlock an extra level of access and content.')}
      </p>

      <LoginOAuthProviders />

      <div className="separator mt-4 mb-4">{t('or')}</div>

      <LoginEmailProvider />

      <Legal />
    </>
  );
}
