import { Amplify } from 'aws-amplify';
import AuthProvider from './contexts/AuthContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Routers from './routes';
import Container from 'components/Container';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_COGNITO_DOMAIN,
    scope: ['email', 'profile', 'openid'],
    redirectSignIn:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : process.env.REACT_APP_AUTHORIZED_REDIRECT_URL,
    redirectSignOut:
      process.env.NODE_ENV === 'development'
        ? 'http://localhost:3000'
        : process.env.REACT_APP_UNAUTHORIZED_REDIRECT_URL,
    responseType: 'token',
  },
});

const App = () => {
  if (!process.env.REACT_APP_RECAPTCHA_CLIENT_ID) {
    throw new Error('REACT_APP_RECAPTCHA_CLIENT_ID is not set.');
  }

  return (
    <Container>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_CLIENT_ID}
      >
        <AuthProvider>
          <Routers />
        </AuthProvider>
      </GoogleReCaptchaProvider>
    </Container>
  );
};

export default App;
