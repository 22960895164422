import { Link, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

type LegalProps = {
  hideLegal?: boolean;
};

const Legal = ({ hideLegal }: LegalProps) => {
  const { pathname } = useLocation();
  const { t } = useTranslation('common');

  return (
    <div className="mt-4 font-size-sm text-center text-muted">
      {hideLegal === true && (
        <Trans
          t={t}
          i18nKey="By proceeding, you are agreeing to College Code's Terms of Service and Privacy Policy."
          components={{
            tosLink: (
              <Link
                to="https://collegecode.com/terms"
                target="_blank"
                rel="noreferrer"
              />
            ),
            privacyLink: (
              <Link
                to="https://collegecode.com/privacy"
                target="_blank"
                rel="noreferrer"
              />
            ),
          }}
        />
      )}

      {pathname !== '/login' && (
        <div className="mt-2 font-size-sm text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="0.6em"
            viewBox="0 0 320 512"
            fill="#869AB8"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
          </svg>{' '}
          <Link to="/login" className="text-muted">
            <Trans t={t} i18nKey="Back to login">
              Back to login
            </Trans>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Legal;
