import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

type SpinnerButtonProps = {
  label: string;
  isLoading: boolean;
};

const SpinnerButton = ({
  label = 'Submit',
  isLoading = false,
}: SpinnerButtonProps) => (
  <Button
    type="submit"
    variant="primary"
    className="btn-block"
    disabled={isLoading}
  >
    {isLoading ? (
      <Spinner
        as="span"
        animation="border"
        variant="light"
        role="status"
        aria-hidden="true"
      />
    ) : (
      label
    )}
  </Button>
);

export default SpinnerButton;
