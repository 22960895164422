import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useTitle, useAuth } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as yup from 'yup';
import type { FormikHelpers } from 'formik';
import Spinner from 'react-bootstrap/Spinner';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { codeRule } from 'validationRules';

const schema = yup.object().shape({
  code: codeRule,
});

type VerifyRequest = {
  code: string;
};

export default function RegisterConfirm() {
  const { t } = useTranslation('verify');
  useTitle(t('Verify Account'));
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { unverifiedAccount, verifyAccount } = useAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleVerifySubmit = useCallback(
    async (
      values: VerifyRequest,
      { setErrors, setStatus }: FormikHelpers<VerifyRequest>
    ): Promise<void> => {
      try {
        if (!executeRecaptcha) {
          throw new Error('Recaptcha not loaded');
        }

        setIsLoading(true);
        await verifyAccount({
          code: values.code,
          recaptcha: await executeRecaptcha('form_submit'),
        });

        setTimeout(() => {
          window.location.href = 'https://collegecode.com';
        }, 1500);
      } catch (err: any) {
        setStatus({ success: false });
        setErrors(err.message);
        setIsLoading(false);
      }
    },
    [verifyAccount, executeRecaptcha, setIsLoading]
  );

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      code: '',
    },
    onSubmit: handleVerifySubmit,
    validateOnChange: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (formik.values.code.trim().length === 6) {
      formik.handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.code]);

  useEffect(() => {
    if (!unverifiedAccount.email || !unverifiedAccount.password) {
      navigate('/login', { replace: true });
    }
  }, [navigate, unverifiedAccount.email, unverifiedAccount.password]);
  console.log('unverifiedAccount', unverifiedAccount);

  return (
    <>
      <h1 className="mb-0 font-weight-bold text-center">
        {t('Verify Your Account')}
      </h1>
      <p className="mb-6 text-center text-muted">
        {t(
          'We have sent a confirmation code to your email address. Please enter the code below to complete sign up.'
        )}
      </p>
      <Form noValidate className="mb-2 mt-2" onSubmit={formik.handleSubmit}>
        <Form.Group className="form-group">
          <Form.Control
            required
            autoFocus
            tabIndex={1}
            type="text"
            name="code"
            maxLength={6}
            className="form-control"
            placeholder={t('6-digit code') || ''}
            autoComplete="off"
            value={formik.values.code}
            onChange={formik.handleChange}
            isInvalid={!!formik.errors.code}
          />
          <Form.Control.Feedback type="invalid">
            {!!formik.errors.code && <>{formik.errors.code}</>}
          </Form.Control.Feedback>
        </Form.Group>

        {/* TODO error message something went wrong  */}

        <Button
          type="submit"
          variant="primary"
          className="btn-block"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting || isLoading ? (
            <Spinner
              as="span"
              animation="border"
              variant="light"
              role="status"
              aria-hidden="true"
            />
          ) : (
            t('Verify')
          )}
        </Button>
      </Form>
    </>
  );
}
