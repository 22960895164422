import * as yup from 'yup';

const passwordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailRule = yup
  .string()
  .max(255, 'Email address must be less than 255 characters')
  .required('Please enter an email address')
  .matches(emailRegex, 'Please enter a valid email address');

export const nameRule = yup
  .string()
  .min(2, 'Your name should be at least 2 characters')
  .max(255, 'Your name must be less than 255 characters')
  .required('Please enter your name');

export const passwordRule = yup
  .string()
  .min(8, 'Password should be at least 8 characters in length')
  .required('Please enter a password')
  .matches(
    passwordRegex,
    'Password must contain at least 1 uppercase, 1 lowercase, 1 number and 1 special case character'
  );

export const passwordConfirmRule = yup.string().when('password', {
  is: (password: string) =>
    password?.length >= 8 && password.match(passwordRegex),
  then: (schema) =>
    schema
      .required('Please confirm your password')
      .oneOf([yup.ref('password')], 'Passwords must match'),
});

export const passwordWithConfirmRule = {
  passwordRule,
  passwordConfirmRule,
};

export const codeRule = yup
  .string()
  .trim()
  .length(6, 'The code must be 6-digits long')
  .required('Please enter the code we emailed you');
