import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import * as yup from 'yup';
import { Link, useSearchParams } from 'react-router-dom';
import { useAuth } from 'hooks';
import { FormikHelpers } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import type { IAuthResult } from 'contexts/AuthContext';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import SpinnerButton from 'components/SpinnerButton';
import { emailRule, passwordRule } from 'validationRules';

type EmailLoginRequest = {
  email: string;
  password: string;
  genericError: string;
};

const schema = yup.object().shape({
  email: emailRule,
  password: passwordRule,
});

const EmailProvider = () => {
  const { login } = useAuth();
  const { t } = useTranslation(['login', 'common']);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('returnUrl');
  const providedEmail = searchParams.get('email');

  const handleSubmit = useCallback(
    async (
      values: EmailLoginRequest,
      { setErrors, setStatus }: FormikHelpers<EmailLoginRequest>
    ): Promise<void> => {
      try {
        if (!executeRecaptcha) {
          throw new Error('Recaptcha has not been loaded');
        }

        const response: IAuthResult = await login({
          email: values.email,
          password: values.password,
          recaptcha: await executeRecaptcha('form_submit'),
        });

        if (!response.success) {
          throw new Error(response.errorCode);
        }

        window.location.href = returnUrl || 'https://collegecode.com';
      } catch (err: any) {
        setStatus({ success: false });

        const localizedError =
          t(`errors.${err.message}`) ||
          t(`errors.${err.message}`, { ns: 'common' }) ||
          t('errors.UnknownException', { ns: 'common' });

        setErrors({ genericError: localizedError });
      }
    },
    [executeRecaptcha, login, returnUrl, t]
  );

  const formik = useFormik({
    validationSchema: schema,
    initialValues: {
      email: providedEmail || '',
      password: '',
      genericError: '',
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <Form noValidate className="mt-2" onSubmit={formik.handleSubmit}>
      <Form.Group className="form-group">
        <Form.Control
          required
          tabIndex={1}
          autoFocus={providedEmail ? false : true}
          type="email"
          name="email"
          className="form-control"
          placeholder={t('Your email address', { ns: 'common' }) || ''}
          autoComplete="off"
          value={formik.values.email}
          onInput={formik.handleChange}
          isInvalid={!!formik.errors.email}
        />
        <Form.Control.Feedback type="invalid">
          {!!formik.errors.email && <>{formik.errors.email}</>}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="form-group mb-1">
        <Form.Control
          required
          tabIndex={1}
          autoFocus={providedEmail ? true : false}
          type="password"
          name="password"
          className="form-control"
          placeholder={t('Your password') || ''}
          value={formik.values.password}
          onInput={formik.handleChange}
          isInvalid={!!formik.errors.password}
        />

        <Form.Control.Feedback type="invalid">
          {!!formik.errors.password && <>{formik.errors.password}</>}
        </Form.Control.Feedback>
      </Form.Group>

      <div className="d-flex align-items-end flex-column bd-highlight mb-3">
        <Link
          to={
            formik.values.email
              ? `/forgot-password?email=${formik.values.email}`
              : '/forgot-password'
          }
          className="font-size-sm text-muted"
        >
          {t('Forgot password?')}
        </Link>
      </div>

      {formik.errors.genericError && (
        <p className="text-danger text-center fs-6 mb-5">
          {formik.errors.genericError}
        </p>
      )}

      <SpinnerButton
        label={t('Login with Email')}
        isLoading={formik.isSubmitting}
      />

      <p className="mt-4 font-size-sm text-center">
        <Link to="/register" className="text-muted">
          {t("Don't have an account? Join now!")}
        </Link>
      </p>
    </Form>
  );
};

export default EmailProvider;
