import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const BaseContainer = ({ children }: { children: React.ReactElement }) => (
  <section className="section-border border-primary">
    <Container className="d-flex flex-column">
      <Row className="align-items-center justify-content-center no-gutters min-vh-100">
        <Col xs={12} md={5} lg={4} className="py-8 py-md-11">
          {children}
        </Col>
      </Row>
    </Container>
  </section>
);

export default BaseContainer;
