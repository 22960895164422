import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { useTranslation } from 'react-i18next';

const OAuthProviders = () => {
  const { t } = useTranslation('login');

  return (
    <div
      className="d-grid gap-2"
      onClick={() =>
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Google,
        })
      }
    >
      <div className="google-btn">
        <div className="google-icon-wrapper">
          <img
            className="google-icon"
            src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
            alt={t('Sign in with Google') || ''}
          />
        </div>
        <p className="btn-text">
          <b>{t('Sign in with Google')}</b>
        </p>
      </div>

      <div
        id="appleid-signin"
        className="signin-button"
        data-color="black"
        data-border="true"
        data-type="sign-in"
      ></div>
    </div>
  );
};

export default OAuthProviders;
