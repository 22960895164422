import { useSearchParams } from 'react-router-dom';
import { useTitle, useAuth } from 'hooks';
import { useCallback } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as yup from 'yup';
import type { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import Legal from 'components/Legal';
import { codeRule, passwordWithConfirmRule } from 'validationRules';
import { useState, useEffect } from 'react';
import SpinnerButton from 'components/SpinnerButton';

const stepsSchemas = [
  yup.object().shape({
    code: codeRule,
  }),
  yup.object().shape({
    password: passwordWithConfirmRule.passwordRule,
    confirmPassword: passwordWithConfirmRule.passwordConfirmRule,
  }),
];

type VerifyRequest = {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
  genericError: string;
};

type StateProps = {
  step: number;
  isReset: boolean;
  isLoading: boolean;
};

export default function PasswordReset() {
  const { t } = useTranslation(['reset-password', 'common']);

  useTitle(t('Reset Password'));

  const { confirmPasswordReset } = useAuth();
  const [searchParams] = useSearchParams();
  const providedEmail = searchParams.get('email');

  const [state, setState] = useState<StateProps>({
    step: 1,
    isReset: false,
    isLoading: false,
  });

  const handleSubmit = useCallback(
    async (
      values: VerifyRequest,
      { setErrors, setStatus }: FormikHelpers<VerifyRequest>
    ): Promise<void> => {
      try {
        if (state.step === 1) {
          return setState((prev) => ({ ...prev, step: 2 }));
        }

        const response = await confirmPasswordReset({
          username: values.email,
          code: values.code,
          newPassword: values.password,
        });

        if (!response.success) {
          throw new Error(response.errorCode);
        }

        /* navigate({
          pathname: '/login',
          search: `?${createSearchParams({ email: values.email })}`,
        }); */
        return setState((prev) => ({ ...prev, isReset: true }));
      } catch (err: any) {
        setStatus({ success: false });

        if (err.message === 'CodeMismatchException') {
          setState((prev) => ({ ...prev, step: 1 }));

          setErrors({
            code: t(`errors.${err.message}`) || err.message,
          });

          return;
        }

        const localizedError =
          t(`errors.${err.message}`, '') ||
          t(`errors.${err.message}`, { ns: 'common', defaultValue: '' }) ||
          t('errors.UnknownException', { ns: 'common' });

        setErrors({ genericError: localizedError });
      }
    },
    [confirmPasswordReset, state.step, t]
  );

  const formik = useFormik({
    validationSchema: stepsSchemas[state.step - 1],
    initialValues: {
      email: providedEmail || '',
      code: '',
      password: '',
      confirmPassword: '',
      genericError: '',
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    if (formik.values.code.trim().length === 6) {
      setState((prev) => ({ ...prev, isLoading: true }));

      setTimeout(() => {
        setState((prev) => ({ ...prev, step: 2, isLoading: false }));
      }, 1500);
    }
  }, [formik.values.code]);

  if (!providedEmail) {
    //navigate('/login', {replace: true});
  }

  return (
    <>
      <h1 className="font-weight-bold text-center">
        {state.isReset ? 'Successful Reset' : t('Reset Password')}
      </h1>

      <p className="mb-6 text-center text-muted">
        {t(
          state.isReset
            ? 'Your password has been reset. Please login with your new credentials.'
            : 'Enter your email address and we will send you a link to reset your password.'
        )}
      </p>

      {!state.isReset && (
        <Form noValidate className="mb-2 mt-2" onSubmit={formik.handleSubmit}>
          {state.step === 1 && (
            <Form.Group className="form-group">
              <Form.Control
                required
                autoFocus
                tabIndex={1}
                type="text"
                name="code"
                maxLength={6}
                className="form-control"
                placeholder="6-digit code"
                autoComplete="off"
                value={formik.values.code.trim()}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.code}
              />
              <Form.Control.Feedback type="invalid">
                {!!formik.errors.code && <>{formik.errors.code}</>}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          {state.step === 2 && (
            <>
              <Form.Group className="form-group">
                <Form.Control
                  required
                  tabIndex={2}
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder={t('New password') || ''}
                  autoComplete="off"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {!!formik.errors.password && <>{formik.errors.password}</>}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Control
                  required
                  tabIndex={3}
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                  placeholder={t('Confirm new password') || ''}
                  autoComplete="off"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  isInvalid={!!formik.errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {!!formik.errors.confirmPassword && (
                    <>{formik.errors.confirmPassword}</>
                  )}
                </Form.Control.Feedback>
              </Form.Group>
            </>
          )}

          {formik.errors.genericError && (
            <p className="text-danger text-center fs-6 mt-5">
              {formik.errors.genericError}
            </p>
          )}

          <SpinnerButton
            label={
              state.step === 1
                ? t('Next', { ns: 'common' })
                : t('Reset Password')
            }
            isLoading={formik.isSubmitting || state.isLoading}
          />
        </Form>
      )}

      <Legal hideLegal={true} />
    </>
  );
}
