import React, { useEffect, useState } from 'react';
import { Auth, Hub } from 'aws-amplify';

export interface IAuthResult {
  errorCode?: string;
  success: boolean;
}

export interface IAuthContextType {
  user: any;
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  unverifiedAccount: { email: string; password: string };

  login: (props: {
    email: string;
    password: string;
    recaptcha: string;
  }) => Promise<IAuthResult>;

  register: (props: {
    name: string;
    email: string;
    password: string;
    recaptcha: string;
  }) => Promise<IAuthResult>;

  verifyAccount: (props: {
    code: string;
    recaptcha: string;
  }) => Promise<IAuthResult>;

  forgotPassword: (props: { username: string }) => Promise<IAuthResult>;

  confirmPasswordReset: (props: {
    username: string;
    code: string;
    newPassword: string;
  }) => Promise<IAuthResult>;
}

export const AuthContext = React.createContext<IAuthContextType>({
  user: null,
  isAuthenticated: false,
  isAuthenticating: true,
  unverifiedAccount: {
    email: '',
    password: '',
  },
  login: async () => ({ success: false }),
  register: async () => ({ success: false }),
  verifyAccount: async () => ({ success: false }),
  forgotPassword: async () => ({ success: false }),
  confirmPasswordReset: async () => ({ success: false }),
});

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: IAuthProviderProps) => {
  const [user, setUser] = useState(null);

  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const [unverifiedAccount, setUnverifiedAccount] = useState({
    email: '',
    password: '',
  });

  /**
   * fetch currently logged-in user using AWS Auth library
   * @returns {Promise<void>}
   */
  const fetchAuthUser = async (): Promise<void> => {
    try {
      const fetchedUser = await Auth.currentAuthenticatedUser();
      setIsAuthenticating(false);
      setUser(fetchedUser);
    } catch (err) {
      setIsAuthenticating(false);
      setUser(null);
    }
  };

  useEffect(() => {
    fetchAuthUser();

    const authListener = Hub.listen('auth', async ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          await fetchAuthUser();
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'signUp_failure':
          if (user) {
            setUser(null);
          }
          break;
        case 'signUp':
        case 'forgotPassword':
        case 'forgotPasswordSubmit':
        case 'forgotPasswordSubmit_failure':
        case 'forgotPassword_failure':
          break;
        default:
          await fetchAuthUser();
      }
    });

    return () => authListener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Login to an existing account
   * @param email - email address of the account
   * @param password - password of the account
   * @param recaptcha - recaptcha token
   * @returns {Promise<IAuthResult>}
   */
  const login = async ({
    email,
    password,
    recaptcha,
  }: {
    email: string;
    password: string;
    recaptcha: string;
  }): Promise<IAuthResult> => {
    return await Auth.signIn({
      username: email,
      password,
      validationData: {
        recaptcha,
      },
    })
      .then(() => ({ success: true }))
      .catch((err) => {
        console.error(`An error occurred logging in: ${err.message}`);
        return { success: false, errorCode: err.code };
      });
  };

  /**
   * Create a new account
   * @param email - email address of the account
   * @param password - password of the account
   * @param name - name of the account
   * @param recaptcha - recaptcha token
   * @returns {Promise<IAuthResult>}
   */
  const register = async ({
    email,
    password,
    name,
    recaptcha,
  }: {
    email: string;
    password: string;
    name: string;
    recaptcha: string;
  }): Promise<IAuthResult> => {
    return await Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
      },
      validationData: {
        recaptcha,
      },
    })
      .then(() => {
        setUnverifiedAccount({ email, password });
        return { success: true };
      })
      .catch((err) => {
        console.log(
          `An error occurred registering the account: ${err.message}`
        );
        return { success: false, errorCode: err.code };
      });
  };

  /**
   * Verify a new account using a confirmation code
   * @param code - confirmation code
   * @returns {Promise<void>}
   */
  const verifyAccount = async ({
    code,
  }: {
    code: string;
  }): Promise<IAuthResult> => {
    return await Auth.confirmSignUp(unverifiedAccount?.email, code)
      .then(() => ({ success: true }))
      .catch((err) => {
        console.log(`An error occurred confirming the account: ${err.message}`);
        return { success: false, errorCode: err.code };
      });
    //await signIn(unverifiedAccount?.email, unverifiedAccount?.password, '');
  };

  /**
   * Request a password reset for an existing account
   * @param username - email address of the account
   * @param recaptcha - recaptcha token
   * @returns {Promise<IAuthResult>}
   */
  const forgotPassword = async ({
    username,
  }: {
    username: string;
  }): Promise<IAuthResult> => {
    return await Auth.forgotPassword(username)
      .then(() => ({
        success: true,
      }))
      .catch((err) => {
        console.error(`An error occurred in forgot password: ${err.message}`);
        return { success: false, errorCode: err.code };
      });
  };

  /**
   * Confirm a password reset using a confirmation code
   * @param username - email address of the account
   * @param otp - confirmation code
   * @param newPassword - new password
   * @returns {Promise<IAuthResult>}
   */
  const confirmPasswordReset = async ({
    username,
    code,
    newPassword,
  }: {
    username: string;
    code: string;
    newPassword: string;
  }): Promise<IAuthResult> => {
    return await Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(() => ({ success: true }))
      .catch((err) => {
        console.error(
          `An error occurred confirming password reset: ${err.message}`
        );
        return { success: false, errorCode: err.code };
      });
  };

  const value = {
    user,
    isAuthenticated: !!user,
    isAuthenticating,
    unverifiedAccount,
    login,
    register,
    verifyAccount,
    forgotPassword,
    confirmPasswordReset,
  };

  if (isAuthenticating) {
    return <div>Loading...</div>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
