import { useNavigate } from 'react-router-dom';
import { useTitle, useAuth } from 'hooks';
import { useCallback, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useFormik } from 'formik';
import * as yup from 'yup';
import type { FormikHelpers } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import Legal from 'components/Legal';
import { nameRule, emailRule, passwordWithConfirmRule } from 'validationRules';
import SpinnerButton from 'components/SpinnerButton';

const stepsSchemas = [
  yup.object().shape({
    name: nameRule,
    email: emailRule,
  }),
  yup.object().shape({
    password: passwordWithConfirmRule.passwordRule,
    confirmPassword: passwordWithConfirmRule.passwordConfirmRule,
  }),
];

type EmailLoginRegistration = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  genericError: string;
};

export default function Register() {
  const { t } = useTranslation(['register', 'common']);
  useTitle(t('Register'));
  const navigate = useNavigate();
  const { register } = useAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [step, setStep] = useState<number>(1);

  const handleSubmit = useCallback(
    async (
      values: EmailLoginRegistration,
      { setErrors, setStatus }: FormikHelpers<EmailLoginRegistration>
    ): Promise<void> => {
      try {
        if (step === 1) {
          return setStep(2);
        }

        if (!executeRecaptcha) {
          throw new Error('Recaptcha has not been loaded');
        }

        const response = await register({
          name: values.name,
          email: values.email,
          password: values.password,
          recaptcha: await executeRecaptcha('form_submit'),
        });

        if (!response.success) {
          throw new Error(response.errorCode);
        }

        navigate('/verify', { replace: true });
      } catch (err: any) {
        setStatus({ success: false });

        const localizedError =
          t(`errors.${err.message}`, '') ||
          t(`errors.${err.message}`, { ns: 'common', defaultValue: '' }) ||
          t('errors.UnknownException', { ns: 'common' });

        setErrors({ genericError: localizedError });
      }
    },
    [step, executeRecaptcha, register, navigate, t]
  );

  const formik = useFormik({
    validationSchema: stepsSchemas[step - 1],
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      genericError: '',
    },
    onSubmit: handleSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <h1 className="mb-0 font-weight-bold text-center">{t('Register')}</h1>
      <p className="mb-6 text-center text-muted">
        Unlock an extra level of access and content.
      </p>
      <Form noValidate className="mb-2 mt-2" onSubmit={formik.handleSubmit}>
        {step === 1 && (
          <>
            <Form.Group className="form-group">
              <Form.Control
                required
                autoFocus
                tabIndex={1}
                type="text"
                name="name"
                className="form-control"
                placeholder={t('What should we call you?') || ''}
                autoComplete="off"
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {!!formik.errors.name && <>{formik.errors.name}</>}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Control
                required
                tabIndex={2}
                type="email"
                name="email"
                className="form-control"
                placeholder={t('Your email address', { ns: 'common' }) || ''}
                autoComplete="off"
                value={formik.values.email}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {!!formik.errors.email && <>{formik.errors.email}</>}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}

        {step === 2 && (
          <>
            <Form.Group className="form-group">
              <Form.Control
                required
                autoFocus
                tabIndex={3}
                type="password"
                name="password"
                className="form-control"
                placeholder={t('Choose a password') || ''}
                autoComplete="off"
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {!!formik.errors.password && <>{formik.errors.password}</>}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="form-group">
              <Form.Control
                required
                tabIndex={4}
                type="password"
                name="confirmPassword"
                className="form-control"
                placeholder={t('Confirm your password') || ''}
                autoComplete="off"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {!!formik.errors.confirmPassword && (
                  <>{formik.errors.confirmPassword}</>
                )}
              </Form.Control.Feedback>
            </Form.Group>
          </>
        )}

        {formik.errors.genericError && (
          <p className="text-danger text-center">
            {formik.errors.genericError}
          </p>
        )}

        <SpinnerButton
          label={step === 1 ? t('Next', { ns: 'common' }) : t('Create Account')}
          isLoading={formik.isSubmitting}
        />
      </Form>

      <Legal />
    </>
  );
}
